var render = function () {
  var _vm$schedules$, _vm$schedules$2, _vm$schedules$3, _vm$schedules$4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DayScheduleStyled', {
    class: {
      'week-list': _vm.displayPeriodUom === 'week'
    }
  }, [_vm.displayPeriodUom === 'week' ? _vm._l(_vm.schedules, function (schedule) {
    return _c('ScheduleDetailsStyled', {
      key: schedule.id,
      staticClass: "schedule",
      attrs: {
        "bgColor": schedule.bgColor
      }
    }, [_c('div', {
      staticClass: "circle"
    }), _c('div', [_vm._v(_vm._s(_vm.scheduleStart(schedule)))]), _c('div', {
      staticClass: "name",
      attrs: {
        "title": schedule === null || schedule === void 0 ? void 0 : schedule.name
      }
    }, [_vm._v(_vm._s(schedule.name))])]);
  }) : [_vm.schedules[0] ? _c('ScheduleDetailsStyled', {
    staticClass: "schedule",
    attrs: {
      "bgColor": _vm.schedules[0].bgColor
    }
  }, [_c('div', {
    staticClass: "circle"
  }), _c('div', [_vm._v(_vm._s(_vm.scheduleStart(_vm.schedules[0])))]), _c('div', {
    staticClass: "name",
    attrs: {
      "title": (_vm$schedules$ = _vm.schedules[0]) === null || _vm$schedules$ === void 0 ? void 0 : _vm$schedules$.name
    }
  }, [_vm._v(_vm._s((_vm$schedules$2 = _vm.schedules[0]) === null || _vm$schedules$2 === void 0 ? void 0 : _vm$schedules$2.name))])]) : _vm._e(), _vm.schedules[1] ? _c('ScheduleDetailsStyled', {
    staticClass: "schedule",
    attrs: {
      "bgColor": _vm.schedules[1].bgColor
    }
  }, [_c('div', {
    staticClass: "circle"
  }), _c('div', [_vm._v(_vm._s(_vm.scheduleStart(_vm.schedules[1])))]), _c('div', {
    staticClass: "name",
    attrs: {
      "title": (_vm$schedules$3 = _vm.schedules[1]) === null || _vm$schedules$3 === void 0 ? void 0 : _vm$schedules$3.name
    }
  }, [_vm._v(_vm._s((_vm$schedules$4 = _vm.schedules[1]) === null || _vm$schedules$4 === void 0 ? void 0 : _vm$schedules$4.name))])]) : _vm._e(), _vm.schedules.length > 2 ? _c('MoreStyled', {
    staticClass: "schedule"
  }, [_vm._v(_vm._s(_vm.$t('planning.more', {
    x: _vm.schedules.length - 2
  })) + " ")]) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }