var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('KeyValueV2AtomStyled', [_c('div', {
    staticClass: "label"
  }, [_c('span', {
    attrs: {
      "title": _vm.label
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + ": ")])]), _c('div', {
    staticClass: "value muted"
  }, [_vm.isLoading ? _c('LoadingDots') : _c('span', [_vm._v(_vm._s(_vm.value))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }