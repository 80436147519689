<template>
  <DayDetailsMolecule>
    <div class="heading">
      <div class="week-day">
        {{ weekday }}
      </div>
      <div class="day">
        {{ dayString }}
      </div>
      <div class="close">
        <CloseButtonAtom @close="$emit('closeDetails')" />
      </div>
    </div>
    <div class="schedules">
      <ScheduleDetailsStyled
        v-for="schedule of schedules"
        :key="schedule?.id"
        :bgColor="schedule.bgColor"
        @click.stop="$emit('edit-schedule', { scheduleId: schedule.id })"
      >
        <div class="circle"></div>
        <div class="time-details">
          <div class="time">
            <div>{{ scheduleStart(schedule) }}</div>
            <div>-</div>
            <div>{{ scheduleEnd(schedule) }}</div>
          </div>
          <div class="timezone">@{{ scheduleTimezone(schedule) }}</div>
        </div>
        <div class="name" :title="schedule?.name">{{ schedule?.name }}</div>
      </ScheduleDetailsStyled>
    </div>
  </DayDetailsMolecule>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Duration } from 'luxon'

import { CloseButtonAtom } from '@common/components'

const DayDetailsMolecule = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 220px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.solidBG};
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 1px rgba(60, 64, 67, 0.15);
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  .heading {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    align-items: center;
    position: relative;
    .week-day {
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.8px;
      line-height: 16px;
      text-transform: uppercase;
    }
    .day {
      font-size: 26px;
      font-weight: 400;
    }
    .close {
      position: absolute;
      right: 0;
    }
  }
  .schedules {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }
`

const ScheduleDetailsStyled = styled('div')`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 10px min-content 1fr;
  grid-gap: 0.5rem;
  align-items: baseline;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ bgColor }) => bgColor};
  }
  .time-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    .time {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      flex-wrap: nowrap;
    }
    .timezone {
      font-size: 12px;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primaryActive};
    cursor: pointer;
  }
`

export default {
  components: {
    DayDetailsMolecule,
    ScheduleDetailsStyled,
    CloseButtonAtom,
  },
  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
    day: {
      type: Date,
    },
    selectedTimezoneId: {
      type: String,
      required: true,
    },
  },
  computed: {
    dayString() {
      return DateTime.fromJSDate(this.day).toLocaleString({ day: '2-digit' })
    },
    weekday() {
      return DateTime.fromJSDate(this.day).toLocaleString({ weekday: 'short' })
    },
  },
  methods: {
    scheduleStart(schedule) {
      if (!schedule?.occurrences[0]) {
        return null
      }
      return this.getDateTimeInTimezoneBySetting(
        DateTime.fromISO(schedule.occurrences[0].date),
        schedule.timezone,
        this.selectedTimezoneId,
      ).toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    scheduleEnd(schedule) {
      if (!schedule?.occurrences[0]) {
        return null
      }
      const start = this.getDateTimeInTimezoneBySetting(
        DateTime.fromISO(schedule.occurrences[0].date),
        schedule.timezone,
        this.selectedTimezoneId,
      )
      const duration = Duration.fromMillis(schedule.occurrences[0].duration)
      const end = start.plus(duration)
      return end.toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    scheduleTimezone(schedule) {
      return `${this.selectedTimezoneId === 'schedule' ? schedule.timezone : this.selectedTimezoneId}`
    },
    getDateTimeInTimezoneBySetting(dt, timezone, setting) {
      if (setting === 'schedule') {
        return dt.setZone(timezone)
      } else if (setting === 'utc') {
        return dt.setZone('UTC')
      } else if (setting === 'local') {
        return dt.setZone('local')
      }
    },
  },
}
</script>
