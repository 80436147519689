<template>
  <MyCalendarsMolecule
    :selectedCalendarId="selectedCalendarId"
    :hiddenScheduleIds="hiddenScheduleIds"
    :isLoading="$apollo.queries.myCalendars.loading"
    :myCalendars="myCalendars"
    @select-calendar="$emit('select-calendar', $event)"
    @toggle-schedule="$emit('toggle-schedule', $event)"
    @create-calendar="createCalendar"
    @edit-calendar="editCalendar"
    @confirm-delete-calendar="confirmDeleteCalendar"
    @add-schedule="addSchedule"
    @edit-schedule="editSchedule"
    @delete-schedule="confirmDeleteSchedule"
  />
</template>
<script>
import MyCalendarsMolecule from '../Molecules/MyCalendarsMolecule.vue'
import MY_CALENDARS_LIST_QUERY from '#/graphql/calendar/myCalendarsWithMinimalSchedules.gql'
import DELETE_CALENDAR_MUTATION from '#/graphql/calendar/delete.gql'
import DELETE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/delete.gql'

export default {
  props: {
    selectedCalendarId: {
      type: String,
    },
    hiddenScheduleIds: {
      type: Array,
      required: true,
    },
  },
  components: {
    MyCalendarsMolecule,
  },
  data() {
    return {
      myCalendars: [],
    }
  },
  methods: {
    createCalendar() {
      this.$root.$emit('activateOverlay', 'CreateCalendarOverlay', {
        createdCallback: this.createdCalendar,
      })
    },
    createdCalendar({ calendarId }) {
      this.$emit('select-calendar', { calendarId })
      this.addSchedule({ calendarId })
    },
    editCalendar({ calendarId }) {
      this.$root.$emit('activateOverlay', 'EditCalendarOverlay', {
        calendarId,
      })
    },
    confirmDeleteCalendar({ calendarId }) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Schedule',
        onConfirm: this.deleteCalendar,
        onConfirmArgs: { calendarId },
      })
    },
    async deleteCalendar({ calendarId }) {
      const res = await this.$apollo.mutate({
        mutation: DELETE_CALENDAR_MUTATION,
        variables: {
          where: {
            id: calendarId,
          },
        },
        update: async store => {
          let { myCalendars } = store.readQuery({
            query: MY_CALENDARS_LIST_QUERY,
          })
          myCalendars = myCalendars.filter(c => c.id !== calendarId)
          store.writeQuery({
            query: MY_CALENDARS_LIST_QUERY,
            data: {
              myCalendars,
            },
          })
        },
      })
      if (this.selectedCalendarId === calendarId) {
        this.$emit('select-calendar', null)
      }
      return !!res
    },
    addSchedule({ calendarId }) {
      this.$root.$emit('activateOverlay', 'CreateScheduleOverlay', {
        calendarId,
        createdCallback: this.closeOverlay,
      })
    },
    closeOverlay() {
      this.$root.$emit('closeOverlay')
    },
    editSchedule({ calendarId, scheduleId }) {
      this.$root.$emit('activateOverlay', 'EditScheduleOverlay', {
        calendarId,
        scheduleId,
        updatedCallback: this.closeOverlay,
      })
    },
    async deleteSchedule({ scheduleId }) {
      const res = await this.$apollo.mutate({
        mutation: DELETE_SCHEDULE_MUTATION,
        variables: {
          where: {
            id: scheduleId,
          },
        },
        update: async store => {
          const { myCalendars } = store.readQuery({
            query: MY_CALENDARS_LIST_QUERY,
          })
          const calendar = myCalendars.find(f => f.schedules.find(ff => ff.id === scheduleId))
          calendar.schedules = calendar.schedules.filter(s => s.id !== scheduleId)
          store.writeQuery({
            query: MY_CALENDARS_LIST_QUERY,
            data: {
              myCalendars,
            },
          })
        },
      })
      return !!res
    },
    confirmDeleteSchedule({ scheduleId }) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Schedule',
        onConfirm: this.deleteSchedule,
        onConfirmArgs: { scheduleId },
      })
    },
  },
  apollo: {
    myCalendars: {
      query: MY_CALENDARS_LIST_QUERY,
    },
  },
}
</script>
