var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MyCalendarSchedulesStyled', [_vm.calendar ? _c('KeyValueV2Atom', {
    staticClass: "owner",
    attrs: {
      "label": _vm._f("capitalize")(_vm.$tc('owner', 1)),
      "value": _vm.calendar.owner.name
    }
  }) : _vm._e(), _c('div', {
    staticClass: "add-schedule"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('schedule', 2))))]), _c('ButtonSolid', {
    attrs: {
      "tooltipText": _vm.$tc('planning.addSchedule')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add-schedule', {
          calendarId: _vm.calendar.id
        });
      }
    }
  }, [_c('PlusIcon', {
    staticClass: "icon",
    attrs: {
      "size": "16"
    }
  })], 1)], 1), _vm.schedules.length === 0 ? _c('div', {
    staticClass: "empty-message"
  }, [_vm._v(_vm._s(_vm.$tc('planning.messages.noSchedules')))]) : _vm._e(), _vm._l(_vm.schedules, function (schedule) {
    return _c('ScheduleListItemMolecule', {
      key: schedule.id,
      attrs: {
        "schedule": schedule,
        "hiddenScheduleIds": _vm.hiddenScheduleIds
      },
      on: {
        "toggle-schedule": function toggleSchedule($event) {
          return _vm.$emit('toggle-schedule', $event);
        },
        "edit-schedule": function editSchedule($event) {
          return _vm.$emit('edit-schedule', $event);
        },
        "delete-schedule": function deleteSchedule($event) {
          return _vm.$emit('delete-schedule', $event);
        },
        "hide-schedule": function hideSchedule($event) {
          return _vm.$emit('hide-schedule', $event);
        },
        "show-schedule": function showSchedule($event) {
          return _vm.$emit('show-schedule', $event);
        }
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }