<template>
  <MyCalendarsMolecule>
    <header>
      <div class="title">{{ $tc('planning.myCalendars') }}</div>
      <ButtonSolid @click="$emit('create-calendar')" :tooltipText="$tc('planning.createCalendar')">
        <CreateButtonStyled>
          <PlusIcon class="icon" />
        </CreateButtonStyled>
      </ButtonSolid>
    </header>
    <main>
      <TalpaLoaderWrapper v-if="isLoading" />
      <template v-else>
        <div class="empty-message" v-if="myCalendars.length === 0">{{ $tc('planning.messages.noCalendars') }}</div>
        <MyCalendarItemMolecule
          v-else
          v-for="calendar in myCalendars"
          :calendar="calendar"
          :selectedCalendarId="selectedCalendarId"
          :hiddenScheduleIds="hiddenScheduleIds"
          :key="calendar.id"
          @select-calendar="$emit('select-calendar', { calendarId: calendar.id })"
          @edit-calendar="$emit('edit-calendar', { calendarId: calendar.id })"
          @confirm-delete-calendar="$emit('confirm-delete-calendar', { calendarId: calendar.id })"
          @toggle-schedule="$emit('toggle-schedule', $event)"
          @add-schedule="$emit('add-schedule', $event)"
          @edit-schedule="$emit('edit-schedule', { ...$event, calendarId: calendar.id })"
          @delete-schedule="$emit('delete-schedule', $event)"
        />
      </template>
    </main>
  </MyCalendarsMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { PlusIcon } from 'vue-feather-icons'

import { ButtonSolid, TalpaLoaderWrapper } from '@common/components'

import MyCalendarItemMolecule from './MyCalendarItemMolecule.vue'

const CreateButtonStyled = styled('div')`
  display: flex;
  font-size: 16px;
  align-items: center;
  gap: 5px;
`

const MyCalendarsMolecule = styled('div')`
  display: grid;
  min-width: 300px;
  grid-template-columns: 1fr;
  grid-template-rows: 76px calc(100vh - 76px - 59px - 1rem);
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 8px;
  overflow: hidden;
  main {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    padding: 0.5rem;
    background: ${({ theme }) => theme.colors.atomic.tableTotal};
    overflow-y: auto;
    .empty-message {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  header {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0.5rem;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG}; */
    .title {
      font-size: 18px;
    }
  }
  button {
    width: 50px;
    border-radius: 50%;
    height: 50px;
  }
`

export default {
  props: {
    selectedCalendarId: {
      type: String,
    },
    hiddenScheduleIds: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    myCalendars: {
      type: Array,
      required: true,
    },
  },
  components: {
    MyCalendarsMolecule,
    PlusIcon,
    ButtonSolid,
    CreateButtonStyled,
    TalpaLoaderWrapper,
    MyCalendarItemMolecule,
  },
}
</script>
