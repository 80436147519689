<template>
  <MyCalendarItemStyled>
    <MyCalendarHeader :class="{ selected: isSelected }">
      <CalendarNameStyled>
        <div class="name" @click="$emit('select-calendar')">
          {{ calendar.name }}
        </div>
        <div class="actions" v-if="isSelected">
          <Edit2Icon @click="$emit('edit-calendar')" />
          <Trash2Icon @click="$emit('confirm-delete-calendar')" />
        </div>
      </CalendarNameStyled>
      <div class="divider" />
      <div class="toggle" @click="expand">
        <ChevronLeftIcon class="icon" :class="{ rotate: isExpanded }" />
      </div>
    </MyCalendarHeader>
    <ScheduleListMolecule
      v-if="isExpanded"
      :calendar="calendar"
      :hiddenScheduleIds="hiddenScheduleIds"
      @toggle-schedule="$emit('toggle-schedule', $event)"
      @add-schedule="$emit('add-schedule', $event)"
      @edit-schedule="$emit('edit-schedule', $event)"
      @delete-schedule="$emit('delete-schedule', $event)"
    />
  </MyCalendarItemStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { ChevronLeftIcon } from 'vue-feather-icons'
import { Trash2Icon, Edit2Icon } from 'vue-feather-icons'

import ScheduleListMolecule from './ScheduleListMolecule.vue'

// import DELETE_CALENDAR_MUTATION from '#/graphql/calendar/delete.gql'
// import MY_CALENDARS_LIST_QUERY from '#/graphql/calendar/myCalendarsWithMinimalSchedules.gql'

const MyCalendarItemStyled = styled('div')`
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  border: solid 1px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  width: 291px;
`
const MyCalendarHeader = styled('div')`
  cursor: pointer;
  display: grid;
  user-select: none;
  grid-template-columns: 240px min-content min-content;
  grid-template-areas: 'name divider toggle';
  grid-template-rows: 1fr;
  padding: 0.5rem;
  width: 275px;
  background-color: ${({ theme }) => theme.colors.widgetBG};
  border-radius: 5px;
  > .toggle {
    grid-area: toggle;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    .feather.rotate {
      transform: rotate(90deg);
    }
  }
  .divider {
    display: flex;
    grid-area: divider;
    margin: 0 0.25rem;
    width: 1px;
    background: ${({ theme }) => theme.colors.atomic.divider};
  }
  &.selected {
    background: ${props => chroma(props.theme.colors.primary).css()};
    color: ${({ theme }) => theme.colors.atomic.white};
    > .toggle {
      color: ${({ theme }) => theme.colors.atomic.white};
    }
  }
  &:hover {
    background: ${props => props.theme.colors.primaryActive};
    color: ${({ theme }) => theme.colors.atomic.white};
    > .toggle {
      color: ${({ theme }) => theme.colors.atomic.white};
    }
    .actions {
      background: linear-gradient(
        to right,
        ${props => chroma(props.theme.colors.primaryActive).alpha(0).css()},
        ${props => chroma(props.theme.colors.primaryActive).css()} 15%
      );
    }
  }
`
const CalendarNameStyled = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  .name {
    flex-grow: 1;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    position: absolute;
    right: 0;
    padding-left: 0.5rem;
    background: linear-gradient(
      to right,
      ${props => chroma(props.theme.colors.primary).alpha(0).css()},
      ${props => chroma(props.theme.colors.primary).css()} 15%
    );
    > svg {
      width: 16px;
      height: 16px;
    }
  }
`

export default {
  components: {
    MyCalendarItemStyled,
    MyCalendarHeader,
    ChevronLeftIcon,
    ScheduleListMolecule,
    CalendarNameStyled,
    Trash2Icon,
    Edit2Icon,
  },
  props: {
    selectedCalendarId: {
      type: String,
    },
    hiddenScheduleIds: {
      type: Array,
      required: true,
    },
    calendar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    isSelected() {
      return this.selectedCalendarId === this.calendar?.id
    },
    scheduleIds() {
      return this.calendar?.schedules.map(s => s.id)
    },
  },
  methods: {
    expand() {
      this.isExpanded = !this.isExpanded
    },
    // select() {
    //   if (this.isSelected) {
    //     return
    //   }
    //   this.$router.push({
    //     query: {
    //       ...this.$route.query,
    //       calendar: this.calendar.id,
    //       schedules: this.scheduleIds,
    //     },
    //   })
    // },
    // async editCalendar() {
    //   this.$root.$emit('activateOverlay', 'CreateCalendarOverlay', {
    //     mode: 'edit',
    //     calendar: this.calendar,
    //   })
    // },
    // async deleteCalendar() {
    //   const res = await this.$apollo.mutate({
    //     mutation: DELETE_CALENDAR_MUTATION,
    //     variables: {
    //       where: {
    //         id: this.calendar.id,
    //       },
    //     },
    //     update: async store => {
    //       let { myCalendars } = store.readQuery({
    //         query: MY_CALENDARS_LIST_QUERY,
    //       })
    //       myCalendars = myCalendars.filter(c => c.id !== this.calendar.id)
    //       store.writeQuery({
    //         query: MY_CALENDARS_LIST_QUERY,
    //         data: {
    //           myCalendars,
    //         },
    //       })
    //     },
    //   })
    //   return !!res
    // },
    // confirmDeleteCalendar() {
    //   this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
    //     type: 'Schedule',
    //     onConfirm: this.deleteCalendar,
    //     onConfirmArgs: this.calendar,
    //   })
    // },
  },
}
</script>
