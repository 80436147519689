<template>
  <MyCalendarSchedulesStyled>
    <KeyValueV2Atom class="owner" v-if="calendar" :label="$tc('owner', 1) | capitalize" :value="calendar.owner.name" />
    <div class="add-schedule">
      <span>{{ $tc('schedule', 2) | capitalize }}</span>
      <ButtonSolid @click="$emit('add-schedule', { calendarId: calendar.id })" :tooltipText="$tc('planning.addSchedule')">
        <PlusIcon class="icon" size="16" />
      </ButtonSolid>
    </div>
    <div class="empty-message" v-if="schedules.length === 0">{{ $tc('planning.messages.noSchedules') }}</div>
    <ScheduleListItemMolecule
      v-for="schedule in schedules"
      :key="schedule.id"
      :schedule="schedule"
      :hiddenScheduleIds="hiddenScheduleIds"
      @toggle-schedule="$emit('toggle-schedule', $event)"
      @edit-schedule="$emit('edit-schedule', $event)"
      @delete-schedule="$emit('delete-schedule', $event)"
      @hide-schedule="$emit('hide-schedule', $event)"
      @show-schedule="$emit('show-schedule', $event)"
    />
  </MyCalendarSchedulesStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { PlusIcon } from 'vue-feather-icons'

import { ButtonSolid } from '@common/components'

import KeyValueV2Atom from '@/components/Atomic/Atoms/KeyValueV2Atom.vue'
import ScheduleListItemMolecule from './ScheduleListItemMolecule.vue'

const MyCalendarSchedulesStyled = styled('div')`
  padding: 0.25rem 0.75rem;
  border-top: solid 1px ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
  background-color: ${p => (p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.mainBG)};
  border-radius: 5px;
  .owner {
    padding: 0.25rem 0rem;
    .value {
      text-align: left;
    }
  }
  .add-schedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-size: 12px;
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 32px;
      height: 32px;
      color: ${({ theme }) => theme.colors.white};
    }
  }
  .empty-message {
    display: flex;
    justify-content: center;
    margin: 1rem 0rem;
  }
`

export default {
  components: {
    MyCalendarSchedulesStyled,
    KeyValueV2Atom,
    ButtonSolid,
    PlusIcon,
    ScheduleListItemMolecule,
  },
  props: {
    calendar: {
      type: Object,
      required: true,
    },
    hiddenScheduleIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredId: null,
    }
  },
  computed: {
    schedules() {
      return this.calendar?.schedules
    },
    selectedCalendarId() {
      return this.$route.query.calendar || null
    },
    selectedScheduleIds() {
      return this.$route.query.schedules || null
    },
  },
  methods: {
    toggleSchedule(id) {
      if (this.calendar.id !== this.selectedCalendarId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            calendar: this.calendar.id,
            schedules: [id],
          },
        })
      } else {
        if (this.selectedScheduleIds.includes(id)) {
          const newSelectedScheduleIds = this.selectedScheduleIds.filter(s => s !== id)
          this.$router.push({
            query: {
              ...this.$route.query,
              schedules: newSelectedScheduleIds,
            },
          })
        } else {
          this.$router.push({
            query: {
              ...this.$route.query,
              schedules: [...this.selectedScheduleIds, id],
            },
          })
        }
      }
    },
    isScheduleSelected(id) {
      return this.selectedScheduleIds ? this.selectedScheduleIds.includes(id) : false
    },
  },
}
</script>
