var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MyCalendarsMolecule', {
    attrs: {
      "selectedCalendarId": _vm.selectedCalendarId,
      "hiddenScheduleIds": _vm.hiddenScheduleIds,
      "isLoading": _vm.$apollo.queries.myCalendars.loading,
      "myCalendars": _vm.myCalendars
    },
    on: {
      "select-calendar": function selectCalendar($event) {
        return _vm.$emit('select-calendar', $event);
      },
      "toggle-schedule": function toggleSchedule($event) {
        return _vm.$emit('toggle-schedule', $event);
      },
      "create-calendar": _vm.createCalendar,
      "edit-calendar": _vm.editCalendar,
      "confirm-delete-calendar": _vm.confirmDeleteCalendar,
      "add-schedule": _vm.addSchedule,
      "edit-schedule": _vm.editSchedule,
      "delete-schedule": _vm.confirmDeleteSchedule
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }