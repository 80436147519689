<template>
  <CalendarMolecule class="calendar-view">
    <CalendarView
      v-if="calendar"
      :show-date="showDate"
      :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
      :disable-past="false"
      :disable-future="false"
      :show-times="false"
      :display-period-uom="displayPeriodUom"
      :display-period-count="displayPeriodCount"
      :starting-day-of-week="startingDayOfWeek"
      :period-changed-callback="periodChanged"
      :locale="locale"
      :itemContentHeight="'2.5rem'"
      @click-date="onClickDay"
    >
      <template #header="{ headerProps }">
        <header>
          <CalendarViewHeader :header-props="headerProps" @input="setShowDate" />
          <CustomSingleSelect
            class="select-uom"
            :selectedOption="selectedUom"
            @selectedFilter="selectUom"
            :options="availableUoms"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $t('plan.showAs') }}:</ShowAsLabel>
            </template>
          </CustomSingleSelect>
          <CustomSingleSelect
            class="select-timezone"
            :selectedOption="selectedTimezone"
            @selectedFilter="selectTimezone"
            :options="availableTimezones"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $t('timezone.timezone') }}:</ShowAsLabel>
            </template>
          </CustomSingleSelect>
        </header>
      </template>
      <template #dayContent="{ day }">
        <DayScheduleMolecule
          :schedules="schedulesForDay(day)"
          :selectedTimezoneId="selectedTimezoneId"
          :displayPeriodUom="displayPeriodUom"
        />
      </template>
    </CalendarView>
    <DetailsStyled v-if="showDetails" :bottom="detailsBottom" :right="detailsRight">
      <DayDetailsMolecule
        :schedules="schedulesForDay(selectedDay)"
        :day="selectedDay"
        :selectedTimezoneId="selectedTimezoneId"
        @closeDetails="closeDayDetails"
        @edit-schedule="$emit('edit-schedule', $event)"
      />
    </DetailsStyled>
  </CalendarMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { Schedule } from '@rschedule/core/generators'
import { CalendarView, CalendarViewHeader, CalendarMathMixin } from 'vue-simple-calendar'
import { CustomSingleSelect } from '@common/components'
import localesMixin from '@/mixins/locales'

import DayScheduleMolecule from './DayScheduleMolecule.vue'
import DayDetailsMolecule from './DayDetailsMolecule.vue'

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const CalendarMolecule = styled('div')`
  position: relative;
  margin: 1rem;
  display: flex;

  height: calc(100% - 2rem);
  flex-direction: column;
  flex-grow: 1;
  .cv-wrapper {
    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .select-uom,
      .select-timezone {
        border-radius: 0.5rem;
        background: ${({ theme }) => theme.colors.atomic.tableTotal};
      }
      button {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.atomic.tableTotal};
        border: none;
        border-radius: 0.5rem;
        padding: 0.6rem 0.7rem;
        margin: 0.25rem;
        font-size: 14px;
        &:hover {
          background-color: ${({ theme }) => theme.colors.atomic.hover};
        }
      }
      .cv-header {
        border: none;
      }
    }
    .cv-weeknumber {
      border: none;
    }
    .cv-header-days {
      border: none;
      .cv-header-day {
        border: none;
      }
    }
    .cv-weeks {
      border: none;
      border-radius: 0.5rem;
      .cv-week {
        .cv-weeknumber {
          display: flex;
          border: none;
          align-items: center;
        }
        .cv-day {
          flex-direction: column;
          border: none;
          padding: 0.2rem;
          margin: 1px 1px 0px 0px;
          background-color: ${({ theme }) => theme.colors.atomic.tableTotal};
          &:hover {
            background-color: ${({ theme }) => theme.colors.atomic.hover};
            cursor: pointer;
          }
          &[aria-selected] {
            background-color: ${({ theme }) => theme.colors.atomic.hover};
          }
        }
      }
    }
    .cv-item {
      border-radius: 0.5rem;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.mainBG};
      border-color: ${({ theme }) => theme.colors.mainBG};
      border: none;
      padding: 0.25rem 0.5rem;
      height: 2.5rem;
      &.toBeContinued {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.continued {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.shift {
        background-color: ${({ theme }) => theme.colors.atomic.dropdownButton};
      }
      &.planned_downtime {
        background-color: ${({ theme }) => theme.colors.atomic.avatar.pink};
      }
      .times {
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
      }
    }
    .schedule {
      font-size: 14px;
    }
  }
`

const DetailsStyled = styled('div')`
  position: absolute;
  bottom: ${({ bottom }) => `${bottom}px`};
  right: ${({ right }) => `${right}px`};
`

export default {
  inject: ['uiSettings', 'theme'],
  mixins: [CalendarMathMixin, localesMixin],
  props: {
    calendar: {
      type: Object,
    },
    hiddenScheduleIds: {
      type: Array,
      required: true,
    },
  },
  components: {
    CalendarMolecule,
    CalendarView,
    CalendarViewHeader,
    CustomSingleSelect,
    ShowAsLabel,
    DayScheduleMolecule,
    DetailsStyled,
    DayDetailsMolecule,
  },
  data() {
    return {
      showDate: new Date(),
      message: '',
      startingDayOfWeek: 0,
      displayPeriodUom: 'month',
      displayPeriodCount: 1,
      showScheduleDialog: false,
      periodRange: null,
      selectedSchedule: null,
      selectedTimezoneId: 'schedule',
      showDetails: false,
      detailsBottom: 0,
      detailsRight: 0,
      selectedDay: null,
    }
  },
  computed: {
    locale() {
      return this.uiSettings?.dates?.toLowerCase().replace('_', '-')
    },
    selectedTimezone() {
      return this.availableTimezones?.find(f => f.id === this.selectedTimezoneId)
    },
    availableTimezones() {
      return [
        { id: 'schedule', label: this.$t('planning.timezoneOptions.schedule') },
        { id: 'local', label: this.$t('planning.timezoneOptions.local') },
        { id: 'utc', label: this.$t('planning.timezoneOptions.utc') },
      ]
    },
    selectedUom() {
      return this.availableUoms?.find(f => f.id === this.displayPeriodUom)
    },
    availableUoms() {
      return ['week', 'month'].map(i => ({ id: i, label: this.$tc(`times.${i}`, 1) }))
    },
    scheduleOccurances() {
      let items = []
      const start = DateTime.fromJSDate(this.periodRange?.displayFirstDate)
      const end = DateTime.fromJSDate(this.periodRange?.displayLastDate)
      if (!end.invalid) {
        items = this.calendar?.schedules
          .filter(f => !this.hiddenScheduleIds.includes(f.id))
          .map((schedule, i) => {
            const s = new Schedule({
              timezone: schedule.timezone,
              rrules: schedule.recurrences.map(recurrence => {
                const r = {
                  start: DateTime.fromISO(recurrence.start).setZone(schedule.timezone),
                  frequency: recurrence.frequency,
                  duration: recurrence.duration,
                  interval: recurrence.interval ? recurrence.interval : undefined,
                  count: recurrence.count ? recurrence.count : undefined,
                  weekStart: recurrence.weekStart ? recurrence.weekStart : undefined,
                  bySecondOfMinute: recurrence.bySecondOfMinute.length > 0 ? recurrence.bySecondOfMinute : undefined,
                  byMinuteOfHour: recurrence.byMinuteOfHour.length > 0 ? recurrence.byMinuteOfHour : undefined,
                  byHourOfDay: recurrence.byHourOfDay.length > 0 ? recurrence.byHourOfDay : undefined,
                  byDayOfWeek: recurrence.byDayOfWeek.length > 0 ? recurrence.byDayOfWeek : undefined,
                  byDayOfMonth: recurrence.byDayOfMonth.length > 0 ? recurrence.byDayOfMonth : undefined,
                  byMonthOfYear: recurrence.byMonthOfYear.length > 0 ? recurrence.byMonthOfYear : undefined,
                }
                return r
              }),
            })
            return {
              ...schedule,
              occurrences: s.occurrences({ start, end }).toArray(),
              s,
              bgColor: this.theme.colors.atomic.pieChartColors[i % 61],
            }
          })
      }
      return items
    },
  },
  methods: {
    schedulesForDay(day) {
      const schedulesOfDay = this.scheduleOccurances.filter(scheduleOcc => {
        const dateTime = DateTime.fromJSDate(day)
        return scheduleOcc.s.occursBetween(dateTime.startOf('day'), dateTime.endOf('day'))
      })
      return schedulesOfDay
    },
    periodChanged(range) {
      this.periodRange = range
    },
    closeScheduleDialog() {
      this.showScheduleDialog = false
      this.selectedSchedule = null
    },
    onClickDay(d) {
      const className = `d${DateTime.fromJSDate(d).toISODate()}`
      const dayEl = document.getElementsByClassName(className)
      const dayPos = dayEl[0].getBoundingClientRect()
      const calEl = document.getElementsByClassName('calendar-view')
      const calPos = calEl[0].getBoundingClientRect()
      this.detailsBottom = calPos.bottom - dayPos.bottom
      this.detailsRight = calPos.right - dayPos.right
      this.showDetails = true
      this.selectedDay = d
    },
    setShowDate(d) {
      this.message = `Changing calendar view to ${d.toLocaleDateString()}`
      this.showDate = d
    },
    selectUom({ id }) {
      this.displayPeriodUom = id
    },
    selectTimezone({ id }) {
      this.selectedTimezoneId = id
    },
    openSchedule(schedule) {
      this.showDetails = false
      this.selectedDay = null
      this.selectedSchedule = schedule
      this.showScheduleDialog = true
    },
    closeDayDetails() {
      this.showDetails = false
      this.selectedDay = null
    },
  },
}
</script>
