var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CalendarMolecule', {
    staticClass: "calendar-view"
  }, [_vm.calendar ? _c('CalendarView', {
    attrs: {
      "show-date": _vm.showDate,
      "time-format-options": {
        hour: 'numeric',
        minute: '2-digit'
      },
      "disable-past": false,
      "disable-future": false,
      "show-times": false,
      "display-period-uom": _vm.displayPeriodUom,
      "display-period-count": _vm.displayPeriodCount,
      "starting-day-of-week": _vm.startingDayOfWeek,
      "period-changed-callback": _vm.periodChanged,
      "locale": _vm.locale,
      "itemContentHeight": '2.5rem'
    },
    on: {
      "click-date": _vm.onClickDay
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var headerProps = _ref.headerProps;
        return [_c('header', [_c('CalendarViewHeader', {
          attrs: {
            "header-props": headerProps
          },
          on: {
            "input": _vm.setShowDate
          }
        }), _c('CustomSingleSelect', {
          staticClass: "select-uom",
          attrs: {
            "selectedOption": _vm.selectedUom,
            "options": _vm.availableUoms,
            "shouldNotResetSelectedOption": true,
            "closeOnSelect": true
          },
          on: {
            "selectedFilter": _vm.selectUom
          },
          scopedSlots: _vm._u([{
            key: "customLabelIcon",
            fn: function fn() {
              return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$t('plan.showAs')) + ":")])];
            },
            proxy: true
          }], null, true)
        }), _c('CustomSingleSelect', {
          staticClass: "select-timezone",
          attrs: {
            "selectedOption": _vm.selectedTimezone,
            "options": _vm.availableTimezones,
            "shouldNotResetSelectedOption": true,
            "closeOnSelect": true
          },
          on: {
            "selectedFilter": _vm.selectTimezone
          },
          scopedSlots: _vm._u([{
            key: "customLabelIcon",
            fn: function fn() {
              return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$t('timezone.timezone')) + ":")])];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }, {
      key: "dayContent",
      fn: function fn(_ref2) {
        var day = _ref2.day;
        return [_c('DayScheduleMolecule', {
          attrs: {
            "schedules": _vm.schedulesForDay(day),
            "selectedTimezoneId": _vm.selectedTimezoneId,
            "displayPeriodUom": _vm.displayPeriodUom
          }
        })];
      }
    }], null, false, 1307459531)
  }) : _vm._e(), _vm.showDetails ? _c('DetailsStyled', {
    attrs: {
      "bottom": _vm.detailsBottom,
      "right": _vm.detailsRight
    }
  }, [_c('DayDetailsMolecule', {
    attrs: {
      "schedules": _vm.schedulesForDay(_vm.selectedDay),
      "day": _vm.selectedDay,
      "selectedTimezoneId": _vm.selectedTimezoneId
    },
    on: {
      "closeDetails": _vm.closeDayDetails,
      "edit-schedule": function editSchedule($event) {
        return _vm.$emit('edit-schedule', $event);
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }