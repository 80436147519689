<template>
  <PlanningAppStyled>
    <MyCalendarsOrganism
      :selectedCalendarId="selectedCalendarId"
      :hiddenScheduleIds="hiddenScheduleIds"
      @select-calendar="selectCalendar"
      @toggle-schedule="toggleSchedule"
    />
    <CalendarOrganism :selectedCalendarId="selectedCalendarId" :hiddenScheduleIds="hiddenScheduleIds" />
  </PlanningAppStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import MyCalendarsOrganism from './Organisms/MyCalendarsOrganism.vue'
import CalendarOrganism from './Organisms/CalendarOrganism.vue'

const PlanningAppStyled = styled('div')`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0.5rem;
  margin: 1rem;
  height: calc(100% - 2rem);
`

export default {
  components: {
    PlanningAppStyled,
    MyCalendarsOrganism,
    CalendarOrganism,
  },
  computed: {
    selectedCalendarId() {
      return this.$route.query.calendar
    },
    hiddenScheduleIds() {
      if (Array.isArray(this.$route.query.hiddenSchedules)) {
        return this.$route.query.hiddenSchedules
      } else if (this.$route.query.hiddenSchedules) {
        return [this.$route.query.hiddenSchedules]
      }
      return []
    },
  },
  methods: {
    selectCalendar(args) {
      if (args?.calendarId === this.selectedCalendarId) {
        return
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          calendar: args?.calendarId,
        },
      })
    },
    toggleSchedule({ scheduleId }) {
      let hiddenScheduleIds = this.hiddenScheduleIds.slice()
      if (this.hiddenScheduleIds.includes(scheduleId)) {
        hiddenScheduleIds = this.hiddenScheduleIds.filter(f => f !== scheduleId)
      } else {
        hiddenScheduleIds.push(scheduleId)
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          hiddenSchedules: hiddenScheduleIds.length > 0 ? hiddenScheduleIds : undefined,
        },
      })
    },
  },
}
</script>
