var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MyCalendarItemStyled', [_c('MyCalendarHeader', {
    class: {
      selected: _vm.isSelected
    }
  }, [_c('CalendarNameStyled', [_c('div', {
    staticClass: "name",
    on: {
      "click": function click($event) {
        return _vm.$emit('select-calendar');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.calendar.name) + " ")]), _vm.isSelected ? _c('div', {
    staticClass: "actions"
  }, [_c('Edit2Icon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('edit-calendar');
      }
    }
  }), _c('Trash2Icon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('confirm-delete-calendar');
      }
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "divider"
  }), _c('div', {
    staticClass: "toggle",
    on: {
      "click": _vm.expand
    }
  }, [_c('ChevronLeftIcon', {
    staticClass: "icon",
    class: {
      rotate: _vm.isExpanded
    }
  })], 1)], 1), _vm.isExpanded ? _c('ScheduleListMolecule', {
    attrs: {
      "calendar": _vm.calendar,
      "hiddenScheduleIds": _vm.hiddenScheduleIds
    },
    on: {
      "toggle-schedule": function toggleSchedule($event) {
        return _vm.$emit('toggle-schedule', $event);
      },
      "add-schedule": function addSchedule($event) {
        return _vm.$emit('add-schedule', $event);
      },
      "edit-schedule": function editSchedule($event) {
        return _vm.$emit('edit-schedule', $event);
      },
      "delete-schedule": function deleteSchedule($event) {
        return _vm.$emit('delete-schedule', $event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }