var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PlanningAppStyled', [_c('MyCalendarsOrganism', {
    attrs: {
      "selectedCalendarId": _vm.selectedCalendarId,
      "hiddenScheduleIds": _vm.hiddenScheduleIds
    },
    on: {
      "select-calendar": _vm.selectCalendar,
      "toggle-schedule": _vm.toggleSchedule
    }
  }), _c('CalendarOrganism', {
    attrs: {
      "selectedCalendarId": _vm.selectedCalendarId,
      "hiddenScheduleIds": _vm.hiddenScheduleIds
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }