<template>
  <CalendarMolecule
    v-if="selectedCalendarId"
    :calendar="calendar"
    :hiddenScheduleIds="hiddenScheduleIds"
    @edit-schedule="editSchedule({ ...$event, calendarId: selectedCalendarId })"
  />
</template>
<script>
import CalendarMolecule from '../Molecules/CalendarMolecule.vue'

import CALENDAR_QUERY from '#/graphql/calendar/show.gql'

export default {
  props: {
    selectedCalendarId: {
      type: String,
    },
    hiddenScheduleIds: {
      type: Array,
      required: true,
    },
  },
  components: {
    CalendarMolecule,
  },
  data() {
    return {
      calendar: null,
    }
  },
  methods: {
    editSchedule({ calendarId, scheduleId }) {
      this.$root.$emit('activateOverlay', 'EditScheduleOverlay', {
        calendarId,
        scheduleId,
      })
    },
  },
  apollo: {
    calendar: {
      query: CALENDAR_QUERY,
      variables() {
        return {
          where: {
            id: this.selectedCalendarId,
          },
        }
      },
      skip() {
        return !this.selectedCalendarId
      },
    },
  },
}
</script>
