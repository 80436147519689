<template>
  <DayScheduleStyled :class="{ 'week-list': displayPeriodUom === 'week' }">
    <template v-if="displayPeriodUom === 'week'">
      <ScheduleDetailsStyled class="schedule" v-for="schedule of schedules" :key="schedule.id" :bgColor="schedule.bgColor">
        <div class="circle"></div>
        <div>{{ scheduleStart(schedule) }}</div>
        <div class="name" :title="schedule?.name">{{ schedule.name }}</div>
      </ScheduleDetailsStyled>
    </template>
    <template v-else>
      <ScheduleDetailsStyled class="schedule" v-if="schedules[0]" :bgColor="schedules[0].bgColor">
        <div class="circle"></div>
        <div>{{ scheduleStart(schedules[0]) }}</div>
        <div class="name" :title="schedules[0]?.name">{{ schedules[0]?.name }}</div>
      </ScheduleDetailsStyled>
      <ScheduleDetailsStyled class="schedule" v-if="schedules[1]" :bgColor="schedules[1].bgColor">
        <div class="circle"></div>
        <div>{{ scheduleStart(schedules[1]) }}</div>
        <div class="name" :title="schedules[1]?.name">{{ schedules[1]?.name }}</div>
      </ScheduleDetailsStyled>
      <MoreStyled class="schedule" v-if="schedules.length > 2">{{ $t('planning.more', { x: schedules.length - 2 }) }} </MoreStyled>
    </template>
  </DayScheduleStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'

const DayScheduleStyled = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.25rem;
  .popup {
    width: 100px;
    height: 200px;
    background-color: green;
  }
  &.week-list {
    justify-content: start;
  }
`
const ScheduleDetailsStyled = styled('div')`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 10px min-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  font-size: 14px;
  font-weight: 400;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ bgColor }) => bgColor};
  }
  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
const MoreStyled = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
`

export default {
  components: {
    DayScheduleStyled,
    ScheduleDetailsStyled,
    MoreStyled,
  },
  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
    selectedTimezoneId: {
      type: String,
      required: true,
    },
    displayPeriodUom: {
      type: String,
    },
  },
  data() {
    return {
      showDayDetails: false,
    }
  },
  computed: {
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  methods: {
    scheduleStart(schedule) {
      if (!schedule?.occurrences[0]) {
        return null
      }
      return this.getDateTimeInTimezoneBySetting(
        DateTime.fromISO(schedule.occurrences[0].date),
        schedule.timezone,
        this.selectedTimezoneId,
      ).toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    editSchedule(schedule) {
      this.$emit('editSchedule', schedule)
    },
    getDateTimeInTimezoneBySetting(dt, timezone, setting) {
      if (setting === 'schedule') {
        return dt.setZone(timezone)
      } else if (setting === 'utc') {
        return dt.setZone('UTC')
      } else if (setting === 'local') {
        return dt.setZone('local')
      }
    },
  },
}
</script>
