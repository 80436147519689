<template>
  <ScheduleListItemMolecule>
    <CheckboxItem :label="schedule.name" :isChecked="isChecked" @change="$emit('toggle-schedule', { scheduleId: schedule.id })" />
    <div class="actions">
      <Edit2Icon @click="$emit('edit-schedule', { scheduleId: schedule.id })" />
      <Trash2Icon @click="$emit('delete-schedule', { scheduleId: schedule.id })" />
    </div>
  </ScheduleListItemMolecule>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { Trash2Icon, Edit2Icon } from 'vue-feather-icons'

import { CheckboxItem } from '@common/components'

const ScheduleListItemMolecule = styled('div')`
  border-bottom: solid 1px ${p => (p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.mainBG)};
  position: relative;
  .actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    align-items: center;
    padding-left: 0.5rem;
    color: ${({ theme }) => theme.colors.primary};
    background: linear-gradient(
      to right,
      ${p =>
        chroma(p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.mainBG)
          .alpha(0)
          .css()},
      ${p => chroma(p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.mainBG).css()} 15%
    );
    > svg {
      width: 16px;
      height: 16px;
      &:hover {
        color: ${({ theme }) => theme.colors.primaryActive};
        cursor: pointer;
      }
    }
  }
  &:hover {
    border-bottom: solid 1px ${({ theme }) => (theme.isDark ? theme.colors.atomic.tableColumnHeaderBG : theme.colors.atomic.divider)};
  }
`

export default {
  components: {
    ScheduleListItemMolecule,
    CheckboxItem,
    Trash2Icon,
    Edit2Icon,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    hiddenScheduleIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredId: null,
    }
  },
  computed: {
    isChecked() {
      return !this.hiddenScheduleIds.includes(this.schedule.id)
    },
  },
  methods: {},
}
</script>
