<template>
  <KeyValueV2AtomStyled>
    <div class="label">
      <span :title="label"> {{ label }}: </span>
    </div>
    <div class="value muted">
      <LoadingDots v-if="isLoading" />
      <span v-else>{{ value }}</span>
    </div>
  </KeyValueV2AtomStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'

const KeyValueV2AtomStyled = styled('div')`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  max-width: 300px;
  .label {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    overflow: hidden;
    max-width: 150px;
    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .value {
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
    display: flex;
    justify-self: end;
  }
  .muted {
    color: ${({ theme }) => (theme.isDark ? theme.colors.muted : theme.colors.dimGrey)};
  }
  @media (max-width: 768px) {
  }
`
export default {
  components: {
    KeyValueV2AtomStyled,
    LoadingDots,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
