var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MyCalendarsMolecule', [_c('header', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$tc('planning.myCalendars')))]), _c('ButtonSolid', {
    attrs: {
      "tooltipText": _vm.$tc('planning.createCalendar')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('create-calendar');
      }
    }
  }, [_c('CreateButtonStyled', [_c('PlusIcon', {
    staticClass: "icon"
  })], 1)], 1)], 1), _c('main', [_vm.isLoading ? _c('TalpaLoaderWrapper') : [_vm.myCalendars.length === 0 ? _c('div', {
    staticClass: "empty-message"
  }, [_vm._v(_vm._s(_vm.$tc('planning.messages.noCalendars')))]) : _vm._l(_vm.myCalendars, function (calendar) {
    return _c('MyCalendarItemMolecule', {
      key: calendar.id,
      attrs: {
        "calendar": calendar,
        "selectedCalendarId": _vm.selectedCalendarId,
        "hiddenScheduleIds": _vm.hiddenScheduleIds
      },
      on: {
        "select-calendar": function selectCalendar($event) {
          return _vm.$emit('select-calendar', {
            calendarId: calendar.id
          });
        },
        "edit-calendar": function editCalendar($event) {
          return _vm.$emit('edit-calendar', {
            calendarId: calendar.id
          });
        },
        "confirm-delete-calendar": function confirmDeleteCalendar($event) {
          return _vm.$emit('confirm-delete-calendar', {
            calendarId: calendar.id
          });
        },
        "toggle-schedule": function toggleSchedule($event) {
          return _vm.$emit('toggle-schedule', $event);
        },
        "add-schedule": function addSchedule($event) {
          return _vm.$emit('add-schedule', $event);
        },
        "edit-schedule": function editSchedule($event) {
          return _vm.$emit('edit-schedule', Object.assign({}, $event, {
            calendarId: calendar.id
          }));
        },
        "delete-schedule": function deleteSchedule($event) {
          return _vm.$emit('delete-schedule', $event);
        }
      }
    });
  })]], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }