var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.selectedCalendarId ? _c('CalendarMolecule', {
    attrs: {
      "calendar": _vm.calendar,
      "hiddenScheduleIds": _vm.hiddenScheduleIds
    },
    on: {
      "edit-schedule": function editSchedule($event) {
        return _vm.editSchedule(Object.assign({}, $event, {
          calendarId: _vm.selectedCalendarId
        }));
      }
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }