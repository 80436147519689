var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ScheduleListItemMolecule', [_c('CheckboxItem', {
    attrs: {
      "label": _vm.schedule.name,
      "isChecked": _vm.isChecked
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('toggle-schedule', {
          scheduleId: _vm.schedule.id
        });
      }
    }
  }), _c('div', {
    staticClass: "actions"
  }, [_c('Edit2Icon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('edit-schedule', {
          scheduleId: _vm.schedule.id
        });
      }
    }
  }), _c('Trash2Icon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('delete-schedule', {
          scheduleId: _vm.schedule.id
        });
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }